import React from 'react';
import barber_icon from './assets/barber_icon.svg';
import prace_0 from './assets/showcase/prace_0.jpg';
import prace_1 from './assets/showcase/prace_1.jpg';
import prace_2 from './assets/showcase/prace_2.jpg';
import prace_3 from './assets/showcase/prace_3.jpg';
import prace_4 from './assets/showcase/prace_4.jpg';
import prace_5 from './assets/showcase/prace_5.jpg';

function Showcase() {
  return (
    <div className="container-fluid showcase mt-5" id="showcase">
      <div className="row text-center">
        <h1>
          Naše Práce <img src={barber_icon} className="heading-icons" alt="ikona" />
        </h1>
      </div>
      <div className="row">
        <div id="showcase" className="col-sm-6 col-md-4 col-lg-2 p-0">
          <img src={prace_1} className="img-fluid showcase_img" data-aos="flip-down" alt="Prace 1" />
        </div>
        <div id="showcase" className="col-sm-6 col-md-4 col-lg-2 p-0">
          <img src={prace_2} className="img-fluid showcase_img" data-aos="flip-down" alt="Prace 2" />
        </div>
        <div id="showcase" className="col-sm-6 col-md-4 col-lg-2 p-0">
          <img src={prace_0} className="img-fluid showcase_img" data-aos="flip-down" alt="Prace 0" />
        </div>
        <div id="showcase" className="col-sm-6 col-md-4 col-lg-2 p-0">
          <img src={prace_3} className="img-fluid showcase_img" data-aos="flip-down" alt="Prace 3" />
        </div>
        <div id="showcase" className="col-sm-6 col-md-4 col-lg-2 p-0">
          <img src={prace_4} className="img-fluid showcase_img" data-aos="flip-down" alt="Prace 4" />
        </div>
        <div id="showcase" className="col-sm-6 col-md-4 col-lg-2 p-0">
          <img src={prace_5} className="img-fluid showcase_img" data-aos="flip-down" alt="Prace 5" />
        </div>
      </div>
    </div>
  );
}

export default Showcase;
