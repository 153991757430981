import oteviraciDoba from './assets/otevriaci-doba.png';
import facebook from './assets/cartel-facebook.png';
import instagram from './assets/cartel-instagram.png';
import tiktok from './assets/cartel-tiktok.png';
import contactIcon from './assets/contact.svg';

function contact() {
  return (
    <div className="container-fluid contactus text-center mb-5" id="contact">
      <div className="row text-center contactus-title py-5">
        <h1>Kontakt Na Nás <img data-aos="fade-up-right" src={contactIcon}  className='heading-icons' alt="ikona" /></h1>
      </div>
      <div className="row text-center contactus-content pb-5">
        <div className="col-auto col-md-3">
          <img data-aos="fade-left" src={oteviraciDoba} className="oteviraci-doba" alt="Oteviraci Doba" />
        </div>
        <div className="col-auto col-md-3 contactus-info" data-aos="fade-down">
          <b>ICO:</b> 19287968 <br />
          <b>Telefon:</b> <a href="tel:773737201" style={{fontWeight: 'bold', textDecoration: 'none', color: '#FFFFFF'}}>(+420) 773 737 201</a> <br />
          <b>Adresa:</b> <br />
          Krupská 264/12 <br />
          415 01, <br />
          Teplice 1, <br />
          Ustecky kraj <br /><br />
          <a href="https://www.facebook.com/profile.php?id=61553082500529" target="_blank" rel="noreferrer"><img src={facebook} alt="Facebook" className='contactus-icon facebook-icon' /></a>
          <a href="https://www.instagram.com/cartelbarbershop_teplice/" target="_blank" rel="noreferrer"><img src={instagram} alt="Instagram" className='contactus-icon instagram-icon' /></a>
          <a href="https://www.tiktok.com/@cartelbarbershopteplice" target="_blank" rel="noreferrer"><img src={tiktok} alt="Tiktok" className='contactus-icon tiktok-icon' /></a>
        </div>
        <div className="col-auto col-md-6" data-aos="fade-right">
            <iframe title="mapa" className="mapa" src="https://maps.google.com/maps?q=Krupska+264%2F12&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0"></iframe>
        </div>
      </div>
    </div>
  );
}

export default contact;