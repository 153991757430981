import React from 'react';
import cartelAbout from './assets/cartel-aboutus.png';
import barberPole from './assets/barber_pole.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

function About() {
  return (
    <div className="aboutus container-fluid mt-5" id="aboutus">
      <div className="aboutus-title row text-center">
        <h1>Něco Malého O Nás <img src={barberPole}  className='heading-icons' alt="ikona" /></h1>
      </div>
      <div className="aboutus-content row text-center mb-5">
        <div data-aos="fade-right" className='col-4 d-flex flex-column align-items-center' style={{borderRight: '1px solid #d8b400'}}>
          <FontAwesomeIcon icon={faQuestion} className="aboutus-question-mark fa-bounce" />
          <img src={cartelAbout} alt="O nas" className="aboutus-img" />
        </div>
        <div data-aos="fade-left" className='col-8 aboutus-text'>
        Společnost Cartel Barbershop představuje jedinečný barbershop, který se specializuje na moderní a vkusné účesy od roku 2023. Jsme hrdí na naše týmy zkušených barberů, kteří mají bohaté zkušenosti a jsou vášniví v tom, co dělají. Naše barbery spojuje lákavá kombinace kreativity, precizního řemesla a dlouholetého know-how.

V Cartel Barbershop nejde jen o stříhání vlasů, ale o celkový zážitek. Každý náš barber je vzdělán v nejnovějších trendech v oblasti účesů a barvení vlasů, aby vám mohl poskytnout služby na nejvyšší úrovni. Chápeme, že vaše vlasy jsou výrazem vaší osobnosti, a proto se věnujeme každému detailu, abychom vytvořili účes, který vám skvěle sedí a cítíte se s ním sebejistě.

Náš prostorný a stylový barbershop je navržen tak, aby vytvořil příjemné a relaxační prostředí. Při návštěvě našeho salonu nejde jen o úpravu vlasů, ale také o chvíle odpočinku a péče o sebe. Nabízíme širokou škálu služeb, včetně klasických střihů, moderních účesů, precizního oholení a péče o vousy.

Věříme, že kvalitní služby musí být doplněny o vynikající zákaznický servis. Naše přátelské a profesionální prostředí je zaměřeno na vaše pohodlí a spokojenost. S námi nejde jen o to, abyste odcházeli s dokonalým účesem, ale také s úsměvem na tváři a příjemným pocitem péče.
        </div>
      </div>
    </div>
  )
}

export default About;
