import React from 'react';
import Header from './header';
import Footer from './footer';

export default function GDPR() {
  return(
    <React.Fragment>
    <Header />
    <div className='gdpr container mt-5'>
      <div className='row text-center'>
        <div className='col'>
          <p>1. Identifikace a Kontaktní Údaje Zodpovědného za Zpracování Údajů<br />
              Work JS s.r.o.<br />
              Husitská 107/3, Praha, 130 00<br />
              <a href="mailto:info@cartelbarber.cz">info@cartelbarber.cz</a><br />
              +420773737201<br />
              IČO: 19287968
          </p>

          <p>2. Účely Zpracování Osobních Údajů<br />
              Osobní údaje, které shromažďujeme (jméno a telefonní číslo), jsou zpracovávány za účelem správy rezervací a komunikace s potenciálními klienty v rámci služeb nabízených v Barbershopu Cartel Barbershop.
          </p>

          <p>3. Právní Základ Zpracování<br />
              Zpracování osobních údajů je nezbytné pro plnění smlouvy (rezervace služeb v Barbershopu) a je v souladu s právními povinnostmi dle GDPR.
          </p>

          <p>4. Kategorie Osobních Údajů<br />
              Shromažďujeme následující kategorie osobních údajů:<br />
              - Jméno<br />
              - Telefonní číslo
          </p>

          <p>5. Příjemci Osobních Údajů<br />
              Osobní údaje mohou být sdíleny s zaměstnanci Barbershopu Cartel Barbershop pro účely správy rezervací a komunikace s klienty.
          </p>

          <p>6. Přenos Osobních Údajů Mimo EU<br />
              Neplánujeme přenášet osobní údaje mimo území Evropské unie (EU).
          </p>

          <p>7. Doba Uchovávání Osobních Údajů<br />
              Osobní údaje budou uchovávány pouze po dobu nezbytně nutnou k dosažení účelu, pro který byly shromážděny, a v souladu s platnými právními předpisy.
          </p>

          <p>8. Práva Subjektů Údajů<br />
              Každý subjekt údajů má právo žádat o přístup k svým osobním údajům, opravu nebo výmaz svých osobních údajů, omezení zpracování, přenositelnost údajů a námitku proti zpracování. Subjekt údajů má také právo podat stížnost u dozorového orgánu.
          </p>

          <p>9. Zabezpečení Osobních Údajů<br />
              Přijmeme veškerá nezbytná opatření k zajištění bezpečnosti osobních údajů, včetně technických a organizačních opatření.
          </p>

          <p>10. Kontakt pro Otázky nebo Žádosti<br />
              Pro jakékoliv otázky nebo žádosti týkající se zpracování osobních údajů nás prosím kontaktujte na <a href="mailto:info@cartelbarber.cz">info@cartelbarber.cz</a>.
          </p>
        </div>
      </div>
    </div>
    <Footer />
    </React.Fragment>
  )
}