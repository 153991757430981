import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";

import App from './App';
import GDPR from './GDPR';

export default function Main() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route index element={<App />} />
        <Route path="/gdpr" element={<GDPR />} />
      </Route>
    )
  );

  return (
    <RouterProvider router={router} />
  );
}