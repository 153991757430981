import React from 'react';
import razor_icon from './assets/razor_icon.svg';
import interior1 from './assets/interior/interior_1.jpg';
import interior2 from './assets/interior/interior_2.jpg';
import interior3 from './assets/interior/interior_3.jpg';
import interior6 from './assets/interior/interior_6.jpg';

function Interior() {
  return (
    <div className="interior py-5 container-fluid" id="interior">
      <div className="row text-center">
        <h1>U Nás v Mafii <img src={razor_icon} className='heading-icons' alt="ikona" /></h1>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3 p-0">
          <div className="image-container">
            <img src={interior1} alt="interior 1" className="img-fluid interior_img" data-aos="flip-down" />
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 p-0">
          <div className="image-container">
            <img src={interior2} alt="interior 2" className="img-fluid interior_img" data-aos="flip-down" />
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 p-0">
          <div className="image-container">
            <img src={interior3} alt="interior 3" className="img-fluid interior_img" data-aos="flip-down" />
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 p-0">
          <div className="image-container">
            <img src={interior6} alt="interior 6" className="img-fluid interior_img" data-aos="flip-down" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interior;
