import {Link} from 'react-router-dom';

import logo from './assets/cartel-footer-logo.png';
import razorLeft from './assets/razor-left.png';
import razorRight from './assets/razor-right.png';

function footer() {
  return (
    <footer className="container-fluid text-center">
      <div className="row footer-content">
        <div className="col-auto col-md-4 footer-content-left">
          Adresa: <br />
          Krupská 264/12, <br />
          415 01, <br />
          Teplice 1 <br />
          Email: rezervace@cartelbarbershop.cz <br />
          Telefon: (+420) 773 737 201
        </div>
        <div className="col-auto col-md-4 footer-content-center">
         <div className="excited-to-see-you-text">
          Těšíme se na vaši návšťevu!
         </div>
         <div className="required-documents-links">
          <Link to="/gdpr">Zpracování osobních údajů / GDPR</Link> <br />
          Stránky vytvořil <a href="mailto:michalsinu@gmail.com" style={{color: '#FFFFFF', textDecoration: 'none'}}><u>Michal Sinu</u></a>
         </div>
        </div>
        <div className="col-auto col-md-4 footer-content-right">
          <div className="text-left">
          <a href="https://www.google.com/search?q=cartel+barbershop+teplice" target="_blank" rel="noreferrer"><button className="btn reviews-button">Recenze Google</button></a> <br /><br />
            IČ: 19287968 <br />
            DIČ: CZ19287968 <br />
            Odpovědná Osoba: Jan Solčani
          </div>
        </div>
      </div>
      <div className="logo-footer">
        <img src={logo} alt="Logo" className='logo-footer-img' />
      </div>
      <div className='razor-left-footer'>
        <img src={razorLeft} alt="Logo" className='razor-left-footer-img' />
      </div>
      <div className='razor-right-footer'>
        <img src={razorRight} alt="Logo" className='razor-right-footer-img' />
      </div>
    </footer>
  );
}

export default footer;