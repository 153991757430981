import cenik from './assets/cenik.png';
import diamond from './assets/diamond.svg';
import kids from './assets/kids.svg';
import crown from './assets/crown.svg';
import barberBrush from './assets/barber_brush_icon.svg';

function Pricing() {
	return (
		<div class="container-fluid text-center cenik" id="pricing">
			<div class="row">
					<div className='col'>
            <h1>Kolik stojí naše kvalita? <img src={barberBrush}  className='heading-icons' alt="ikona" /></h1>
          </div>
			</div>
      <div className='row cenik-content'>
        <div className='col-md-6'>
          <img src={cenik} alt="Ceník" className="cenik_img" data-aos="fade-left" />
        </div>
        <div className='col-md-6 cenik-text' data-aos="fade-right">
          <img src={diamond} alt="diamond" className="cenik-icons" />
          <br />
          V našem barbershopu si můžete opravdu užít jedinečný zážitek. Přijďte k nám, abyste si mohli odpočinout, dát si skvělý drink, kávu nebo osvěžující nápoj, a zároveň si užít ten luxus moderního střihu. Naše profesionální a přátelské prostředí vám zaručí nejen skvělý vzhled, ale i relaxační chvíle plné péče o vaši image. Věnujeme se nejen preciznímu stříhání, ale také celkovému osobnímu servisu. Přijďte k nám a dopřejte si nezapomenutelný pobyt ve stylu a pohodě!
          <br /><br />
          <img src={kids} alt="kids" className="cenik-icons" />
          <br />
          V našem barbershopu vítáme všechny generace! U nás nejsou vítáni pouze dospělí, ale také naši nejmenší zákazníci. S radostí striháme i děti, abychom zajistili, že celá rodina si u nás najde svůj styl. Naši zkušení barberové mají schopnost vytvořit dětem účesy, které nejen zdůrazní jejich jedinečný charakter, ale také budou praktické a snadno udržovatelné. S hravým přístupem a profesionálním zázemím se postaráme o to, aby i vaše děti odcházely s úsměvem na tváři a s pěkným účesem na hlavě. Přijďte k nám jako rodina a zažijte příjemný okamžik péče o vzhled pro všechny členy domácnosti!
          <br /><br />
          <img src={crown} alt="crown" className="cenik-icons" />
          <br />
          V našem barbershopu neklademe důraz pouze na perfektní střihy, ale poskytujeme i širokou škálu dalších služeb pro kompletní péči o váš vzhled. Můžete si u nás užít relaxační masáž hlavy, která nejenže odstraní stres, ale také přidá k celkovému zážitku návštěvy našeho salonu. Naši zkušení barberové se specializují na úpravu kontur obličeje, barvení vousů a vlasů podle nejnovějších trendů.

Pro dokonalý vzhled nabízíme také zarovnání obočí a profesionální face mask, která poskytne vaší pleti potřebnou péči a osvěžení. Abychom dosáhli maximálního komfortu, provádíme i jemné vytrhávání chloupků z nosu a uší. Každý detail je pro nás důležitý, a proto vám garantujeme nejen skvělý vzhled, ale i pohodlný a relaxační zážitek během celé návštěvy našeho barbershopu. Vaše spokojenost je pro nás prioritou!
        </div>
      </div>
		</div>
	);
}

export default Pricing;