import React, { useState } from 'react';
import logo from './assets/logo.png';

function Header() {
  const [displayMenu, setDisplayMenu] = useState(false);

  const toggleMenu = () => {
    setDisplayMenu(!displayMenu);
  };

  return (
    <div className='header-stay-on-top'>
    <header className="header">
      <img src={logo} alt="logo" className="logo" />

      <div className="menu">
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
      {displayMenu && (
        <div className="menu-activated">
          <ul>
            <li>
              <a href="/#aboutus">O Nás</a>
            </li>
            <li>
              <a href="/#showcase">Naše Práce</a>
            </li>
            <li>
              <a href="/#qualification">Kvalifikace</a>
            </li>
            <li>
              <a href="/#interior">Galerie Interioru</a>
            </li>
            <li>
              <a href="/#contact">Informace</a>
            </li>
            <li>
              <a href="/#pricing">Ceník</a>
            </li>
            <li>
              <a href="/gdpr">GDPR</a>
            </li>
          </ul>
        </div>
      )}
    </header>

    <div className='announcement text-center'><marquee behavior="scroll" direction="right" scrollamount="10">REZERVUJTE ‼️ VÝHODNÁ AKCE ‼️
👉 PŘIPRAVILI JSME SI PRO VÁS SPECIÁLNÍ AKCI❗️
💈 Od 24.11. do KONCE ROKU!! KOMPLETKA POUZE ZA 520,- 💈
PÁNSKÝ STŘIH POUZE ZA 360,-💈
DĚTSKÝ STŘIH ZA 250,-💈
</marquee></div>
    </div>
  );
}

export default Header;
