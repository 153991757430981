import rekvalifikace from './assets/rekvalifikace.jpg';
import barberAcademy from './assets/barber-akademie.png';
import quality from './assets/quality.svg';

function Qualification() {
	return (
		<div class="container-fluid text-center kvalifikace" id="qualification">
			<div class="row mb-5">
        <div className='col kvalifikace-heading'>
          <h1>Vynikáme v Umění naší Práce: Jsme Mistři ve Svém Řemesle <img src={quality}  className='heading-icons' alt="ikona" /></h1>
        </div>
			</div>

      <div className='row kvalifikace-content'>
        <div className='col-md-6'>
          <img data-aos="fade-right" src={rekvalifikace} alt="Kvalifikace" className="kvalifikace_img" />
        </div>

        <div data-aos="fade-down" className='col-md-6 kvalifikace-text'>
          <img data-aos="zoom-in" src={barberAcademy} alt="barberAcademy" className="barber-akadmie" /><br />
          V Cartel Barber Shop nejsme jen obyčejným holičstvím, jsme garancí kvality a stylu. Naši barbeři disponují kvalifikací od prestižní Barber Akademie Czech Barber Academy, což znamená, že tvé vlasy jsou v rukou opravdových odborníků. Nemusíš se ničeho obávat – jsme tu pro tebe s vášní pro detail a dokonalým uměním barberingu!
          <br /><br />
          Czech Barber Academy nám poskytla nejen certifikaci, ale také špičkový výcvik a znalosti nejnovějších trendů v oblasti úprav vlasů a vousů. Jsme neustále v kroku s časem a přinášíme do našeho Barber Shopu ty nejmodernější techniky a styly, abychom tě vždy naladili na aktuální módní vlnu.
          <br /><br />
          V Cartel Barber Shop nás spojuje láska k řemeslu a péči o naše zákazníky. Každý náš střih je pro nás uměleckým dílem, ať už jde o klasický střih, precizní úpravu vousů nebo moderní styling. Přijď k nám, abys zažil jedinečný zážitek v atmosféře, kde se setkává tradiční kvalita s inovativním přístupem.
        </div>
      </div>
		</div>
	);
}

export default Qualification;