import './App.css';
import './mobile.css';

import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Header from './header';
import About from './about';
import Showcase from './showcase';
import Qualification from './qualification';
import Interior from './interior';
import Contact from './contact';
import Pricing from './pricing';
import Footer from './footer';

import barber_icon from './assets/barber_icon.svg';
import time_icon from './assets/time_icon.svg';
import mouse from './assets/mouse.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';

import AOS from 'aos';

function App() {
  const [calendar, setCalendar] = useState('');
  const [times, setReservationTimes] = useState('');
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const monthInWords = [
    "Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"
  ];

  function displayAvailableReservationTimes(day) {
    const year = new Date().getFullYear();
    const date = year + '-' + month + '-' + day;

    axios.post('/reservation.php', {
      action: 'display_available_times',
      date: date,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(response => {
      setReservationTimes(response.data);

      // Use the state updater function to get the most up-to-date state
      setMonth(prevMonth => {
        const selectedDay = document.getElementById('reservation-available_selected').innerHTML;
        document.getElementById('date').value = new Date().getFullYear() + '-' + prevMonth + '-' + selectedDay;

        return prevMonth;
      });
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  useEffect(() => {
    console.log('Effect is running');
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 300,
    });

    getCalendarFromMonth(month);

    // Attach event listener to document for event delegation
    document.addEventListener('click', handleDocumentClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [month]);

  const getCalendarFromMonth = (currentMonth) => {
    axios.post('/reservation.php', {
      action: 'build_calendar_from_month',
      month: currentMonth,
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(response => {
      setCalendar(response.data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const handleNextMonth = () => {
    setMonth(prevMonth => {
      if (prevMonth !== 12) {
        console.log('Next Month:', prevMonth + 1);
        return prevMonth + 1;
      } else {
        return prevMonth = month;
      }
    });
  }

  const handlePreviousMonth = () => {
    setMonth(prevMonth => {
      const currentMonth = new Date().getMonth() + 1;
      if (prevMonth !== currentMonth) {
        console.log('Previous Month:', prevMonth - 1);
        return prevMonth - 1;
      } else {
        return prevMonth = month;
      }
    });
  }

  const handleDocumentClick = (event) => {
    if (event.target.id === 'toggle-reservation-service') {
      if (document.getElementById('reservation-service').style.display === 'none' || document.getElementById('reservation-service').style.display === '') {
        document.getElementById('reservation-service').style.display = 'block';
      } else {
        document.getElementById('reservation-service').style.display = 'none';
      }
    }

    if(event.target.id === 'reservation-service-selection') {
      const selectedService = document.querySelectorAll('#reservation-service-selected');

      selectedService.forEach(element => {
        element.removeAttribute('id');
        element.setAttribute('id', 'reservation-service-selection');
      });

      event.target.setAttribute('id', 'reservation-service-selected');

      document.getElementsByClassName('reservation-calendar')[0].style.display = 'block';
      document.getElementById('reservation-selection').scrollIntoView();
      document.getElementById('service').value = event.target.value;
    }

    if (event.target.id === 'reservation-available') {
      const selectedElements = document.querySelectorAll('#reservation-available_selected');

      selectedElements.forEach(element => {
        element.removeAttribute('id');
        element.setAttribute('id', 'reservation-available');
      });

      event.target.setAttribute('id', 'reservation-available_selected');

      displayAvailableReservationTimes(event.target.innerHTML);
    }

    if (event.target.id === 'available_reservation_time') {
      const selectedElements = document.querySelectorAll('#available_reservation_time_selected');
      selectedElements.forEach(element => {
        element.removeAttribute('id');
        element.setAttribute('id', 'available_reservation_time');
      });
      event.target.setAttribute('id', 'available_reservation_time_selected');
      document.getElementById('form-reservation-details').style.display = 'block';
      document.getElementById('time').value = document.getElementById('available_reservation_time_selected').innerHTML;
    }
  }

  function updateCustomerReservationDetails(customerDetail, customerDetailType) {
    document.getElementById(customerDetailType).value = customerDetail.target.value;
  }

  function submitReservation(event) {
    event.preventDefault();

    const customerServiceSelection = document.getElementById('service').value;
    const customerDateSelection = document.getElementById('date').value;
    const customerTimeSelection = document.getElementById('time').value;
    const customerName = document.getElementById('name').value;
    const customerMobileNumber = document.getElementById('mobile_number').value;

    axios.post('/reservation.php', {
      action: 'reservation',
      service: customerServiceSelection,
      date: customerDateSelection,
      time: customerTimeSelection,
      name: customerName,
      mobile_number: customerMobileNumber
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(response => {
      document.getElementById('response-message').innerHTML = response.data;
      window.location.reload();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  return (
    <React.Fragment>
      <Header />

      <div className="jumbotron container-fluid">
        <div className='row'>
          <div data-aos="fade-in" className='col-md-6 jumbotron_typography'>
            <h1>Vítejte v našem</h1>
            <h1>Elegantním</h1>
            <h2>BARBERSHOPU</h2>
          </div>
          <div data-aos="fade-left" className='col-md-6 reservation'>
            <h4>Rezervace</h4>

            <div className='container'>
              <div className='row'>
                <div className='col'>
                  <button className='btn reservation_button' id="toggle-reservation-service"><img src={barber_icon} alt="Vyberte službu" className='icon' /> Vyberte Službu</button>
                </div>
              </div>
              <div className='row' id='reservation-service'>
                <div className='col'>
                  <ul>
                    <li><button className='btn' id='reservation-service-selection' value='Klasika'><b>Klasika</b> - Střih, styling - <b>360 Kč</b></button></li>
                    <li><button className='btn' id='reservation-service-selection' value='Kompletka'><b>Kompletka</b> - Střih, mytí, oprava kontur/vousů/obočí, styling - <b>520 Kč</b></button></li>
                    <li><button className='btn' id='reservation-service-selection' value='Holení'><b>Holení</b> - Ošetření, napaření horkým ručníkem, úprava vousu: strojek/shaver/břitva, úprava kontur vousů, úprava obočí, aplikace kolínské - <b>360 Kč</b></button></li>
                    <li><button className='btn' id='reservation-service-selection' value='Barvy na vousy'><b>Barvy na vousy</b> - Barvení vousů - <b>280 Kč</b></button></li>
                    <li><button className='btn' id='reservation-service-selection' value='Děti do 13 let'><b>Děti do 13 let</b> - Střih, mytí, styling - <b>250 Kč</b></button></li>
                    <li><button className='btn' id='reservation-service-selection' value='Cartel barber full service'><b>Cartel barber full service</b> - Střih,vousy, mytí,  masáž hlavy, vysoušení, úprava kontur a obočí, zaholení uší, holení, Hot Towel, aplikace kolinské,barva na vousy, procedura černá maska, styling, napoj, alkohol - <b>1090 Kč</b></button></li>
                  </ul>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <button className='btn reservation_button' id="reservation-selection"><img src={time_icon} alt="Vyberte datum a Čas" className='icon' /> Vyberte Datum a Čas</button>
                </div>
              </div>
              <div className='container reservation-calendar mt-2 mb-5'>
                <div className='row text-left calendar-actions'>
                  <div className='col'>
                    <button type="button" className='btn calendar-action-buttons previous-button' onClick={handlePreviousMonth}>&lt;</button>
                      {monthInWords[month-1]}
                    <button type="button" className='btn calendar-action-buttons next-button' onClick={handleNextMonth}>&gt;</button>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className="calendar" dangerouslySetInnerHTML={{ __html: calendar }} />
                </div>
                <div className='col'>
                <div className="times" dangerouslySetInnerHTML={{ __html: times }} />
                </div>
              </div>
              <form style={{display: 'none'}} id="form-reservation-details" onSubmit={submitReservation}>
              <div className='row mt-5'>
                <div className='col-3'>
                  Vaše Jméno:
                </div>
                <div className='col-9'>
                  <input type="text" className='reservation-details' onChange={(e) => updateCustomerReservationDetails(e, 'name')} required />
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-3'>
                  Vaše Tel. Číslo:
                </div>
                <div className='col-9'>
                  <input type="tel" pattern='[0-9]{3}[0-9]{3}[0-9]{3}' className='reservation-details' placeholder="123456789" onChange={(e) => updateCustomerReservationDetails(e, 'mobile_number')} required />
                </div>
              </div>
              <div className='row'>
                <div className='col mt-5'>
                    <input type='hidden' id='service' />
                    <input type='hidden' id='name' />
                    <input type='hidden' id='mobile_number' />
                    <input type='hidden' id='date' />
                    <input type='hidden' id='time' />
                   <button type='submit' className='btn reservation-submit btn-warning' onClick={console.log('click')}>REZERVOVAT</button>
                   <div id="response-message"></div>
                </div>
              </div>
              </form>
            </div>
              <div className='row reservation_info'>
                <div className='col'>
                  Nebo jednoduše zavolejte na číslo <a href="tel:773737201" style={{fontWeight: 'bold', textDecoration: 'none', color: '#FFFFFF'}}>(+420) 773 737 201</a> a náš tým profesionálů vám rád pomůže s objednávkou.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row' style={{marginTop: '7%'}}>
          <div className='col scroll_down'>
            <img src={mouse} className="scroll-down-btn icon" alt="Scroll down" />
          </div>
        </div>
      </div>

      <About />
      <Showcase />
      <Qualification />
      <Interior />
      <Contact />
      <Pricing />
      <Footer />
    </React.Fragment>
  );
}

export default App;
